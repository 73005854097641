// 生产环境
let config = {
  baseUrl: 'http://localhost:9900', // 网关地址，一般使用内网地址
  tokenName: 'EDU_WEB_TOKEN', // token名称
  tokenExpires: 60 * 60 * 1000, // token 过期时间为1个小时
  // url: '172.16.1.202:32447',
  isShowShoppingCart: true, // 是否显示购物车
  tryLookNum: 3 * 60 // 试看时长，3分钟
}

if (process.env.NODE_ENV === 'development') {
  // 开发环境
  config = {
    ...config,
    baseUrl: 'http://172.16.1.202:32600', // 开发网关地址http://172.16.1.202:32600
    // baseUrl: 'http://172.16.1.208:32600', // 开发网关地址http://172.16.1.202:32600
    // baseUrl: 'http://192.1.1.15:9900', // 开发网关地址http://172.16.1.202:32600
    // baseUrl: 'http://172.16.28.69:9900', // 开发网关地址http://172.16.1.202:32600
    isShowShoppingCart: true,
    url: '172.16.1.202:32447' // 店铺域名，本地开发使用，生产不用配置该属性
  }
}
export default config
